import React from 'react';
import Layout from '../components/Layout';
import babyInABox from '../assets/images/little-boy-crying-inside-a-box.jpg';
const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Movers Who Also Pack</h2>
          <p>
            {' '}
            Curious about having movers provide a full packing service? Could be
            a good solution for you.
          </p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <span className="image left">
            <img src={babyInABox} alt="little boy crying inside a box" />
          </span>
          <h3 className="major"> Packing is a lot of work </h3>

          <p>
            {' '}
            To make sure everything will get to your new home safe, everything
            has to be safely packed. For some people this task just takes more
            time than it's worth dealing with. Or maybe you prefer having the
            confidence of knowing that your valuables were packed by
            professionals who know exactly how to package each thing best.
            That's why we offer a full packing service.
          </p>
        </div>
      </div>
    </section>
    <section id="wrapper">
      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={''} alt="" />
          </a>
          <div className="content">
            <h2 className="major">We bring all the packing materials</h2>
            <p>
              Forget about sourcing all the best boxes. Don't worry about the
              bubble wrap. Or getting a hundred rolls of tape. We will bring all
              of the packing materals we could possibly need. Hanging wardrobe
              boxes so that your nicely hung clothes can go right from your old
              closet to the new one, with all the same crisp creases in the
              right places. Got flatscreens? We will bubble wrap your
              television, and get it securely tucked into a TV box so that night
              one in your new place you'll be watching Netflix or the big game
              with plenty of time to spare.
            </p>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={''} alt="" />
          </a>
          <div className="content">
            <h2 className="major">So the movers pack everything?</h2>
            <p>
              When we say everything we mean everything.{' '}
              <strong>Everything.</strong> Have a garage full of...stuff? No
              problem. Storage shed full of more...stuff? We got you. For
              particularly large jobs the movers will come out and spend an
              entire day just packing. Every single dish and cup wrapped in
              tissue paper, and bubble wrap, before it gets tightly tucked into
              a double thick specialty dishpack lined with another layer of
              bubble wrap. Your kitchen drawers and cupboards emptied carefully
              one by one and packed into moving boxes. Paintings and decorations
              professionaly packed in the same exact method we have been using
              to ship artwork for galleries and private collectors. Got glass?
              No sweat, we will professionaly package all of your glass from the
              dining room table to the end tables in bubble wrap, shrink wrap,
              layers of corrugated cardboard and custom built wooden crates.
              Getting your things safely to your new home is guaranteed when
              it's done right the first time. Once everything is packaged
              perfectly we will load up the truck and be on the way to meet you
              for the delivery.
            </p>
          </div>
        </div>
      </section>
    </section>
  </Layout>
);
export default IndexPage;
